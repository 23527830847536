import React from "react";
import "../styles/home.css";

import { Container, Row, Col } from "reactstrap";
// import heroImg from '../assets/images/hero-img01.jpg';
// import heroImg02 from '../assets/images/hero-img02.jpg';
import heroImg03 from "../assets/images/hero-img03.jpg";
import heroImg04 from "../assets/images/hero-img04.jpg";
import heroVideo from "../assets/images/hero-video.mp4";
// import worldImg from '../assets/images/world.png';
import palmImg from "../assets/images/palm-bg-no.png";
import experienceImg from "../assets/images/experience.png";

import Subtitle from "../shared/Subtitle";
import SearchBar from "../shared/SearchBar.jsx";
import ServiceList from "../services/ServiceList";
import FeaturedTourList from "../components/Featured-tours/FeaturedTourList";
import MasonryImagesGallery from "../components/image-gallery/MasonryImagesGallery";
import Testimonials from "../components/Testimonial/Testimonials";
import Newsletter from "../shared/Newsletter";

const Home = () => {
  return (
    <>
      {/* ############### Hero section start ############### */}

      <section>
        <Container>
          <Row>
            <Col lg="6">
              <div className="hero__content">
                <div className="hero__subtitle d-flex align-items-center">
                  <Subtitle subtitle={"Untold Travel Adventures Awaits"} />
                  <img src={palmImg} alt="" />
                </div>
                <h1>
                  Traveling opens the door to creating{" "}
                  <span className="highlight"> memories</span>
                </h1>
                <p>
                  Untold Bookings is your gateway to the stunning beauty of Cape
                  Town. Experience the breathtaking landscapes, vibrant culture,
                  and luxurious accommodations that this iconic city has to
                  offer. Whether you’re looking to explore Table Mountain,
                  stroll through the V&A Waterfront, or relax at ANEW Hotels &
                  Resorts, we have the perfect package for you. Book with us
                  today and uncover the untold stories of your next Cape Town
                  adventure.
                </p>
              </div>
            </Col>

            <Col lg="2">
              <div className="hero__img-box mt-5">
                <img src={heroImg04} alt="" />
              </div>
            </Col>

            <Col lg="2">
              <div className="hero__img-box hero__video-box mt-4">
                <video src={heroVideo} alt="" controls />
              </div>
            </Col>

            <Col lg="2">
              <div className="hero__img-box">
                <img src={heroImg03} alt="" />
              </div>
            </Col>

            <SearchBar />
          </Row>
        </Container>
      </section>

      {/* ############### Hero section end ############### */}

      <section>
        <Container>
          <Row>
            <Col lg="3">
              <h5 className="services__subtitle">What we serve</h5>
              <h2 className="services__title">We offer our best services</h2>
            </Col>
            <ServiceList />
          </Row>
        </Container>
      </section>

      {/* ############### Featured tour section start ############### */}

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5">
              <Subtitle subtitle={"Explore"} />
              <h2 className="featured__tour-title">Our featured tours</h2>
            </Col>
            <FeaturedTourList />
          </Row>
        </Container>
      </section>

      {/* ############### Featured tour section end ############### */}

      {/* ############### Experience section start ############### */}

      <section>
        <Container>
          <Row>
            <Col lg="6">
              <div className="experience__content">
                <Subtitle subtitle={"Experience"} />

                <h2>
                  With all our experience <br /> we will serve you
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectur adipiscing elit.
                  <br />
                  Quas aliguam, hic tempora inventore suscipit unde.
                </p>
              </div>

              <div className="counter__wrapper d-flex align-items-center gap-5">
                <div className="counter__box">
                  <span>12k+</span>
                  <h6>Successful trip</h6>
                </div>
                <div className="counter__box">
                  <span>2k+</span>
                  <h6>Successful clients</h6>
                </div>
                <div className="counter__box">
                  <span>2+</span>
                  <h6>Years experience</h6>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="experience__img">
                <img src={experienceImg} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* ############### Experience section end ############### */}

      {/* ############### Gallery start ############### */}
      <section>
        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={"Gallery"} />
              <h2 className="gallery__title">
                Visit our customer tour gallery
              </h2>
            </Col>
            <Col lg="12">
              <MasonryImagesGallery />
            </Col>
          </Row>
        </Container>
      </section>
      {/* ############### Gallery end ############### */}

      {/* ############### Testimonial section start ############### */}

      <section>
        <Container>
          <Row>
            <Col lg="12">
              <Subtitle subtitle={"Fans Love"} />
              <h2 className="testimonial__title">What our fans say about us</h2>
            </Col>
            <Col lg="12">
              <Testimonials />
            </Col>
          </Row>
        </Container>
      </section>

      {/* ############### Testimonial section end ############### */}

      {/* ############### Newsletter section start ############### */}

      <Newsletter />

      {/* ############### Newsletter section end ############### */}
    </>
  );
};

export default Home;
