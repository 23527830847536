import React from 'react';
import Slider from 'react-slick';
import ava01 from '../../assets/images/ava-1.jpg';
import ava02 from '../../assets/images/ava-2.jpg';
import ava03 from '../../assets/images/ava-3.jpg';

const Testimonials = () => {
  
  const settings = {
    dots:true,
    infinite:true,
    autoplay:true,
    speed:1000,
    swipeToSlide:true,
    autoplayspeed:2000,
    slidesToShow:3,

    responsive:[
      {
        breakpoint:992,
        settings: {
          slidesToShow:2,
          slideToScroll:1,
          infinite:true,
          dots:true,
        },
      },
      {
        breakpoint:576,
        settings: {
          slidesToShow:2,
          slideToScroll:1,
        },
      },
    ],
  };
  
  return (
    <Slider { ...settings } >
      <div className='testimonials py-4 px-3'>
        <p>With this travel agency, our journey became a stress-free, authentic adventure. Their expertly curated itineraries and exceptional service made our trip unforgettable. They're a symbol of carefree, rich travel. Highly recommended!</p>

        <div className='d-flex align-items-center gap-4 mt-3'>
          <img src={ava01} className='w-25 h-25 rounded-2' alt='' />
          <div>
            <h6 className='mb-0 mt-3'>John Doe</h6>
            <p>Customer</p>
          </div>
        </div>
      </div>

      <div className='testimonials py-4 px-3'>
        <p>This travel company delivered a once-in-a-lifetime experience, turning travel into a seamless exploration. Their thoughtful itineraries highlighted unseen gems, enriching our journey. For an authentic, hassle-free adventure, look no further.</p>

        <div className='d-flex align-items-center gap-4 mt-3'>
          <img src={ava02} className='w-25 h-25 rounded-2' alt='' />
          <div>
            <h6 className='mb-0 mt-3'>John Doe</h6>
            <p>Customer</p>
          </div>
        </div>
      </div>

      <div className='testimonials py-4 px-3'>
        <p>Exceptional service and meticulously curated trips are this company's forte. They transformed our travel experience into a unique, stress-free journey, unearthing local hidden treasures. For those seeking a truly enriching and carefree travel experience, we recommend them wholeheartedly.</p>

        <div className='d-flex align-items-center gap-4 mt-3'>
          <img src={ava03} className='w-25 h-25 rounded-2' alt='' />
          <div>
            <h6 className='mb-0 mt-3'>John Doe</h6>
            <p>Customer</p>
          </div>
        </div>
      </div>

      <div className='testimonials py-4 px-3'>
        <p>Exceptional service and meticulously curated trips are this company's forte. They transformed our travel experience into a unique, stress-free journey, unearthing local hidden treasures. For those seeking a truly enriching and carefree travel experience, we recommend them wholeheartedly.</p>

        <div className='d-flex align-items-center gap-4 mt-3'>
          <img src={ava02} className='w-25 h-25 rounded-2' alt='' />
          <div>
            <h6 className='mb-0 mt-3'>John Doe</h6>
            <p>Customer</p>
          </div>
        </div>
      </div>
    </Slider>
  )
}

export default Testimonials;
